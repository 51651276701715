<template>
    <div>
        <h3>Installment History  of {{  $route.params.uid }}</h3>
        <el-table :data="items" border v-loading="loading" class="text-center">
            <el-table-column prop="index" type="index" label="Installment No."  width="150px" align="center"/>
            <el-table-column prop="date" label="Date" align="center"/>
            <el-table-column prop="date" label="Amount to be paid" align="center">
                <template #default="scope">
                    {{ scope.row.amount+scope.row.due }}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="Paid Amount" align="center"/>
            <el-table-column prop="due" label="Due" align="center"/>
        </el-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'InstallmentHistory',
    data() {
        return {
            items: [],
            loading: false,
        }
    },
    created() {
        this.getInvoice();
    },
    methods: {
        getInvoice() {
            axios.get(`/api/v2/invoices/${this.$route.params.uid ? this.$route.params.uid : this.$route.query.uid}`)
            .then((res) => {
            if (res.data.status_code == 500) {
                this.$notify({
                title: 'Failed',
                message: res.data.message,
                type: 'danger',
                duration: 2000,
                });
            }
            this.items = res.data.data.installments;
            });
        },
    },

}
</script>